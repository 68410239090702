<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 19:21:03
-->
<template>
  <div class="greenchain ptn_r">
    <div class="w_auto greenSupply ptn_r">
      <div class="horizontal"></div>
      <div class="horizontal_2 flax">
        <p v-if="$store.state.language === 'cn'">当前位置: {{tatleName}}</p>
        <p v-else>Location: {{tatleName}}</p>
      </div>

      <div class=" about Impact">
        <div>Suppliers’ Criterion </div>
        <div>供应商评审规则</div>
      </div>
      <div class="flex">
        <div class="grnin ">
          <div @click="add(14)" :class="['flex grnin_1 ',flagNam == 14 ? 'green_bg': 'green_color']">
            <p>绿色采购指南</p>
          </div>
          <div @click="add(15)" :class="['flex grnin_1 ',flagNam == 15 ? 'green_bg': 'green_color']">
            <p>白名单评审规则</p>
          </div>
          <div @click="add(16)" :class="['flex grnin_1 ',flagNam == 16 ? 'green_bg': 'green_color']">
            <p>绿名单评审规则</p>
          </div>
          <div @click="add(17)" :class="['flex grnin_1 ',flagNam == 17 ? 'green_bg': 'green_color']">
            <p>黑名单评审规则</p>
          </div>
        </div>
        <div class="TheContainer">
          <div class="wh">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flagNam: 14,
      tatleName: '供应商白名单'
    }
  },
  mounted() {
    this.flagNam = this.$route.meta.flagNam
    // this.add(this.flagNam)

    this.hub.$on('tatleNames', () => {
      // console.log(this.$route,12487);
      this.flagNam = this.$route.meta.flagNam
      // console.log(this.flagNam,this.$route.meta.flagNam);
      this.add(this.flagNam)
    })
  },
  methods: {

    add(val) {
      this.flagNam = val
      // this.tatleName =
      switch (val) {
        case 14:
          this.tatleName = '绿色采购指南';
          if (this.$route.name != 'procurementGreen') {

            this.$router.push({ name: 'procurementGreen' })
          }
          break;
        case 15:
          this.tatleName = '白名单评审规则';
          if (this.$route.name != 'procurementWhite') {

            this.$router.push({ name: 'procurementWhite' })
          }

          break;
        case 16:
          this.tatleName = '绿名单评审规则';
          if (this.$route.name != 'procurementGreens') {

            this.$router.push({ name: 'procurementGreens' })
          }

          break;
        case 17:
          this.tatleName = '黑名单评审规则';
          if (this.$route.name != 'procurementBlack') {

            this.$router.push({ name: 'procurementBlack' })
          }

          break;

      }
    }
  }
}
</script>
<style lang="less" scoped>
.ptn_r {
  position: relative;
}
.flex {
  display: flex;
}
.greenchain {
  min-height: 500px;
  overflow: hidden;
}
.horizontal {
  position: absolute;
  left: -200px;
  width: 540px;
  margin-top: 246px;
  border-top: 1px solid #666;
}
.horizontal_2 {
  position: absolute;
  top: 240px;
  left: 460px;
  width: 40000px;
  border-top: 5px solid #8fa926;
  p {
    position: absolute;
    top: -50px;
    border-left: 4px solid #8fa926;
    margin-left: 2px;
    padding-left: 20px;
    font-size: 18px;
  }
}
.about {
  position: absolute;
  top: 120px;
  left: 40px;
  // width: 240px;
  div:nth-of-type(1) {
    font-size: 35px;
    font-weight: 700;
    color: #8fa926;
  }
  div:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
.grnin {
  padding-top: 320px;
  margin-left: 60px;
  width: 280px;
  .grnin_1 {
    // justify-content: center;
    // padding-left: 20px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;

    p {
      width: 280px;
      text-align: center;
      //   left: -30px;
    }
  }
}
.green_color {
  color: #8fa926;
}
.green_bg {
  background: #8fa926;
  color: #fff;
}
.TheContainer {
  padding: 247px 0 0 120px;
  flex: 1;
  .wh {
    min-height: 800px;

    width: 100%;
  }
}
</style>